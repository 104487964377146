import { baseTheme, danas, net } from './net'
import { danasDark, netDark } from './netDark'

export const themes = {
    baseTheme,
    net,
    netDark,
    danas,
    danasDark
}
