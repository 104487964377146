// eslint-disable-next-line no-unused-vars, import/named
import { breakpoints as globalBreakpoints, toPx } from '../../../helpers/theme'
import { colorsDefault } from '../../globals/colors'
import layoutDimension from './dimensions'
import grid from './grid'

const { values: breakpointValuesArr, unit } = globalBreakpoints

const modifiedLayoutBreakpointsArr = Object.values(breakpointValuesArr).map(bp => (bp !== 0 ? `${bp}${unit}` : `${bp}`))

const layoutTheme = {
    breakpoints: modifiedLayoutBreakpointsArr,
    variants: {
        // Grid variants
        ...grid,
        zeroDimensions: {
            width: 0,
            height: 0,
            padding: 0,
            margin: 0
        },
        // Pages
        // Homepage
        homepage: {
            main: {
                flexDirection: 'column',
                width: '100%',
                paddingBottom: '50px',
                backgroundColor: colorsDefault.background.default,

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '100px'
                }
            },

            content: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.homepage?.content),
                paddingLeft: '0',
                paddingRight: '0',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            }
        },
        // Article Layout
        article: {
            ads: {
                billboard: {
                    width: '100%',
                    maxWidth: toPx(layoutDimension?.article?.main),
                    paddingLeft: '0',
                    paddingRight: '0',
                    margin: 'auto',

                    '@media screen and (min-width: 768px)': {
                        paddingLeft: '25',
                        paddingRight: '25'
                    }
                },
                floating: {
                    width: '100%',
                    height: '100%',
                    marginTop: '20px'
                },
                floatingColumn: {
                    width: '100%',
                    height: '100%',
                    marginTop: '20px'
                }
            },
            returnFromAmp: {
                paddingBottom: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '25px'
                }
            },
            head: {
                flexDirection: 'row',
                justifyContent: 'stretch',
                alignItems: 'stretch',
                width: '100%',
                paddingTop: '0',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '25px'
                },

                content: {
                    width: '100%',
                    maxWidth: toPx(layoutDimension?.article?.head),
                    paddingRight: '0',
                    paddingLeft: '0',

                    '@media screen and (min-width: 768px)': {
                        paddingRight: '25px',
                        paddingLeft: '25px'
                    }
                },
                aside: {
                    flex: `0 0 ${toPx(layoutDimension?.article?.aside)}`,
                    display: 'none',
                    flexDirection: 'column',
                    justifyContent: 'stretch',
                    paddingRight: '0',
                    paddingLeft: '0',
                    maxWidth: toPx(layoutDimension?.article?.aside),

                    '@media screen and (min-width: 768px)': {
                        display: 'block',
                        paddingRight: '25px',
                        paddingLeft: '25px'
                    }
                }
            },

            main: {
                flexDirection: 'column',
                width: '100%',
                maxWidth: toPx(layoutDimension?.article?.main),
                paddingTop: '25px',
                margin: 'auto',
                backgroundColor: colorsDefault.background.default,

                '@media screen and (min-width: 768px)': {
                    paddingTop: '25px'
                }
            },
            mainContent: {
                width: '100%',
                alignItems: 'strech',
                maxWidth: toPx(layoutDimension?.article?.main),

                body: {
                    // containerType: 'inline-size',
                    // containerName: 'ifrContainer',

                    width: '100%',
                    flexDirection: 'column',
                    maxWidth: toPx(layoutDimension?.article?.content)
                },
                aside: {
                    flexDirection: 'column',
                    flex: '1 0 100%',
                    display: 'none',
                    width: '100%',
                    paddingRight: '15px',
                    paddingTop: '25px',
                    paddingLeft: '15px',
                    maxWidth: toPx(layoutDimension?.article?.aside),
                    minWidth: '300px',

                    '@media screen and (min-width: 768px)': {
                        display: 'block',
                        paddingRight: '25px',
                        paddingTop: '25px',
                        paddingLeft: '25px'
                    }
                }
            },
            facebook_ugc: {
                display: 'flex',
                width: '100%',
                maxWidth: toPx(layoutDimension?.article?.main),
                paddingRight: '15px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            },
            content: {
                width: '100%',
                paddingLeft: '15px',
                paddingRight: '15px',
                maxWidth: toPx(layoutDimension?.article?.content),

                '@media screen and (min-width: 768px)': {
                    paddingLeft: '25px',
                    paddingRight: '25px'
                }
            },
            cta: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.article?.content),
                paddingRight: '15px',
                paddingBottom: '30px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '50px',
                    paddingBottom: '25px',
                    paddingLeft: '50px'
                }
            },
            ugc: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.article?.aside),
                paddingRight: '15px',
                paddingBottom: '30px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '50px',
                    paddingBottom: '25px',
                    paddingLeft: '50px'
                }
            },
            prevNext: {
                margin: 'auto',
                paddingTop: '25px',
                paddingBottom: '15px',
                width: '100%',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '35px',
                    paddingBottom: '15px'
                }
            },
            meta: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: toPx(layoutDimension?.article?.content),
                padding: '20px 15px 30px',

                '@media screen and (min-width: 768px)': {
                    padding: '25px 25px 50px'
                }
            },

            mightBeInterested: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.article?.content),
                paddingRight: '15px',
                paddingBottom: '30px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '50px',
                    paddingBottom: '25px',
                    paddingLeft: '50px'
                }
            },
            mightBeInterestedAlt: {
                display: 'grid !important',
                gridTemplateColumns: '1fr',
                gridColumnGap: '0',
                padding: '10px 15px',

                '@media screen and (min-width: 768px)': {
                    gridTemplateColumns: '2fr 1fr',
                    gridColumnGap: '1.875rem',
                    padding: '20px 25px'
                }
            },
            mightBeInterestedAltAmp: {
                display: 'flex',
                flexDirection: 'column',
                gridTemplateColumns: '1fr',
                gridColumnGap: '0rem',
                padding: '10px 15px',

                '@media screen and (min-width: 768px)': {
                    flexDirection: 'row',
                    gridTemplateColumns: '2fr 1fr',
                    gridColumnGap: '30px',
                    padding: '20px 25px'
                }
            },
            moreArticles: {
                display: 'grid !important',
                gridTemplateColumns: '1fr',
                gridColumnGap: '0px',
                paddingRight: '15px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingLeft: '25px',
                    paddingRight: '25px'
                }
            },
            // needs separate variant for amp because of !important
            moreArticlesAmp: {
                display: 'flex',
                flexDirection: 'column',
                gridColumnGap: '0px',

                paddingLeft: '15px',
                paddingRight: '15px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    gridColumnGap: '30px',
                    flexDirection: 'row',
                    paddingLeft: '25px',
                    paddingRight: '25px'
                }
            },

            josIzRubrike: {
                alignItems: 'top',
                paddingBottom: '30px',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '30px'
                }
            },
            izdvojeno: {
                paddingRight: '15px',
                paddingBottom: '15px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '10px',
                    paddingLeft: '25px'
                }
            },
            regional: {
                paddingLeft: '15px',
                paddingRight: '15px',
                paddingBottom: '30px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '50px',
                    paddingLeft: '25px'
                }
            },

            qpq: {
                paddingRight: '15px',
                paddingBottom: '10px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '15px',
                    paddingLeft: '25px'
                }
            },
            linker: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.article?.main),
                paddingRight: '15px',
                paddingBottom: '10px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '10px',
                    paddingLeft: '25px'
                }
            },
            facebook: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.article?.content),
                paddingRight: '15px',
                paddingBottom: '20px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '50px',
                    paddingLeft: '25px'
                }
            },
            newsletter: {
                width: '100%',
                paddingRight: '15px',
                paddingBottom: '30px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '50px',
                    paddingLeft: '25px',
                    paddingTop: '50px'
                }
            },
            list: {
                width: '100%',
                paddingRight: '15px',
                paddingBottom: '30px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '50px',
                    paddingLeft: '25px'
                }
            },
            listalica: {
                width: '100%',
                maxWidth: '1170px',
                margin: 'auto',
                paddingTop: '20px',
                paddingRight: '15px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '30px',
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            },
            columnist: {
                '@media screen and (min-width: 768px)': {
                    paddingBottom: '50px'
                }
            }
        },

        zodiac: {
            main: {
                flexDirection: 'column',
                width: '100%',
                maxWidth: toPx(layoutDimension?.article?.main),
                paddingTop: '25px',
                margin: 'auto',
                backgroundColor: colorsDefault.background.default,

                '@media screen and (min-width: 768px)': {
                    paddingTop: '25px'
                }
            },

            head: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.article?.head),
                paddingBottom: '23px',
                paddingLeft: '15px',
                paddingRight: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '30px',
                    paddingLeft: '25px',
                    paddingRight: '25px'
                }
            },

            content: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.article?.content),
                paddingRight: '15px',
                paddingBottom: '13px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '42px',
                    paddingLeft: '25px'
                }
            },

            meta: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.article?.content),
                paddingBottom: '63px',
                paddingLeft: '15px',
                paddingRight: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '47px',
                    paddingLeft: '25px',
                    paddingRight: '25px'
                }
            },

            card: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.article?.content),
                paddingRight: '15px',
                paddingBottom: '57px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '77px',
                    paddingLeft: '25px'
                }
            }
        },
        media: {
            ads: {
                billboard: {
                    margin: 'auto',
                    width: '100%',
                    maxWidth: '1170px',
                    paddingRight: '0px',
                    paddingLeft: '0px',

                    '@media screen and (min-width: 768px)': {
                        paddingRight: '25px',
                        paddingLeft: '25px'
                    }
                },

                floating: {
                    width: '100%',
                    height: '100%',
                    marginTop: '20px'
                }
            },
            main: {
                flexDirection: 'row',
                width: '100%',
                maxWidth: toPx(layoutDimension?.media?.main),
                paddingTop: '25px',
                margin: 'auto',
                backgroundColor: colorsDefault.background.inverted,

                '@media screen and (min-width: 768px)': {
                    paddingTop: '25px'
                },

                aside: {
                    flex: '0 0 420px',
                    flexDirection: 'column',
                    justifyContent: 'stretch',
                    display: 'none',
                    maxWidth: toPx(layoutDimension?.article?.aside),
                    paddingRight: '0px',
                    paddingLeft: '0px',

                    '@media screen and (min-width: 768px)': {
                        display: 'block',
                        paddingRight: '25px',
                        paddingLeft: '25px'
                    }
                }
            },

            content: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.media?.content)
            },

            cta: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.media?.content),
                paddingRight: '15px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            },

            meta: {
                width: '100%',
                padding: '30px 15px 30px',
                maxWidth: toPx(layoutDimension?.media?.content),

                '@media screen and (min-width: 768px)': {
                    padding: '40px 25px 50px'
                }
            },

            related: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.media?.related),
                paddingBottom: '50px',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '80px'
                },

                latest: {
                    width: '100%',
                    paddingRight: '15px',
                    paddingLeft: '15px',

                    '@media screen and (min-width: 768px)': {
                        paddingRight: '25px',
                        paddingLeft: '25px'
                    },

                    '@media screen and (min-width: 1024px)': {
                        maxWidth: toPx(layoutDimension?.media?.content)
                    }
                },
                popular: {
                    width: '100%',
                    paddingRight: '15px',
                    paddingLeft: '15px',

                    '@media screen and (min-width: 768px)': {
                        paddingRight: '25px',
                        paddingLeft: '25px'
                    },

                    '@media screen and (min-width: 1024px)': {
                        maxWidth: toPx(layoutDimension?.media?.aside)
                    }
                }
            },

            relatedMobile: {
                display: 'flex',
                flexDirection: 'column-reverse',
                // alignItems: 'center',
                width: '100%',
                // maxWidth: toPx(layoutDimension?.media?.related),
                paddingBottom: '50px',

                '@media screen and (min-width: 1024px)': {
                    paddingBottom: '80px',
                    flexDirection: 'row'
                }
            }
        },
        // Tag Layout
        tag: {
            ads: {
                billboard: {
                    margin: 'auto',
                    width: 'auto'
                },

                listing: {
                    gridColumn: 'span 12'
                }
            },

            main: {
                flexDirection: 'row',
                alignItemd: 'stretch',
                width: '100%',
                maxWidth: toPx(layoutDimension?.tag?.main),
                padding: '30px 15px 50px',
                margin: 'auto',
                backgroundColor: colorsDefault.background.default,

                '@media screen and (min-width: 768px)': {
                    padding: '40px 25px 80px'
                },

                aside: {
                    flexDirection: 'column',
                    display: 'none',
                    width: '100%',
                    maxWidth: toPx(layoutDimension?.article?.aside),
                    paddingRight: '15px',
                    paddingTop: '25px',
                    paddingLeft: '15px',

                    '@media screen and (min-width: 768px)': {
                        display: 'flex',
                        paddingRight: '25px',
                        paddingTop: '25px',
                        paddingLeft: '25px'
                    }
                }
            },
            listing: {
                display: 'flex',
                flexDirection: 'column',
                maxWidth: toPx(layoutDimension?.tag?.listing),
                width: '100%',

                article: {
                    width: '100%',
                    paddingBottom: '15px',

                    '@media screen and (min-width: 768px)': {
                        paddingBottom: '30px'
                    }
                }
            },
            loadMore: {
                paddingRight: '25px',
                paddingBottom: '30px',
                paddingLeft: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '50px',
                    paddingLeft: '25px'
                }
            }
        },
        // Search Layout
        search: {
            ads: {
                billboard: {
                    margin: 'auto',
                    width: '100%'
                },
                listing: {
                    gridColumn: '1 / last-col'
                }
            },
            main: {
                flexDirection: 'row',
                width: '100%',
                maxWidth: toPx(layoutDimension?.search?.main),
                paddingRight: '15px',
                paddingBottom: '50px',
                paddingLeft: '15px',
                margin: 'auto',
                backgroundColor: colorsDefault.background.default,

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '80px',
                    paddingLeft: '25px'
                },

                aside: {
                    flexDirection: 'column',
                    alignSelf: 'stretch',
                    display: 'none',
                    width: '100%',
                    maxWidth: toPx(layoutDimension?.article?.aside),
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    paddingTop: '25px',

                    '@media screen and (min-width: 768px)': {
                        display: 'flex',
                        paddingLeft: '25px',
                        paddingRight: '25px',
                        paddingTop: '25px'
                    }
                }
            },

            head: {
                width: '100%',
                flexDirection: 'column'
            },

            listing: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: toPx(layoutDimension?.search?.listing),
                paddingTop: '30px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '40px'
                },

                article: {
                    width: '100%',
                    paddingBottom: '15px',

                    '@media screen and (min-width: 768px)': {
                        paddingBottom: '30px'
                    }
                }
            },
            details: {
                alignItems: 'center'
            },
            pagination: {
                marginTop: '15px',

                '@media screen and (min-width: 768px)': {
                    marginTop: '30px'
                }
            },
            noResults: {
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'center',

                icon: {
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '100px',
                    paddingBottom: '100px',

                    '@media screen and (min-width: 768px)': {
                        paddingTop: '200px',
                        paddingBottom: '200px'
                    }
                }
            }
        },
        // Static (page) Layout
        static: {
            main: {
                flexDirection: 'column',
                paddingTop: '30px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '50px'
                }
            },

            head: {
                paddingBottom: '25px',
                maxWidth: '1300px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '55px'
                }
            },

            content: {
                maxWidth: '950px',
                paddingRight: '25px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            }
        },
        // Error Layout
        error: {
            main: {
                flexDirection: 'column',
                width: '100%',
                maxWidth: '1150px',
                padding: '80px 25px',
                margin: 'auto',
                backgroundColor: colorsDefault.background.default,

                '@media screen and (min-width: 768px)': {
                    padding: '100px 25px 120px'
                }
            },

            head: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.error?.head),
                paddingBottom: '80px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '100px'
                }
            },

            list: {
                width: '100%'
            }
        },
        // Category Layout
        category: {
            ads: {
                billboard: {
                    width: '100%'
                },

                listing: {
                    width: '100%',
                    gridColumn: 'span 12'
                },

                floating: {
                    marginTop: '20px',
                    width: '100%',
                    height: '100%'
                }
            },

            main: {
                maxWidth: toPx(layoutDimension?.category?.main),
                padding: '30px 15px 50px',
                margin: 'auto',
                backgroundColor: colorsDefault.background.default,

                '@media screen and (min-width: 768px)': {
                    padding: '15px 15px 80px'
                }
            },

            sidebarItem: {
                alignItems: 'top',
                paddingBottom: '30px',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '50px'
                }
            },

            widgets: {
                maxWidth: toPx(layoutDimension?.category?.main),
                width: '100%'
            },

            showMoreBtn: {
                padding: '20px 0',

                '@media screen and (min-width: 768px)': {
                    padding: '30px 15px'
                }
            },

            privacyBtn: {
                paddingTop: '20px',
                paddingBottom: '20px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '30px',
                    paddingBottom: '30px'
                }
            },

            aside: {
                flexDirection: 'column',
                alignSelf: 'stretch',
                display: 'none',
                width: '100%',
                maxWidth: toPx(layoutDimension?.archive?.aside),
                paddingTop: '25px',
                paddingRight: '15px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    display: 'flex',
                    paddingRight: '30px',
                    paddingLeft: '30px'
                }
            },

            listing: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: toPx(layoutDimension?.category?.listing),
                paddingTop: '15px',

                article: {
                    width: '100%',
                    paddingBottom: '15px',

                    '@media screen and (min-width: 768px)': {
                        paddingBottom: '30px'
                    }
                }
            },
            button: {
                width: '100%',
                maxWidth: 'layoutDimension?.category?.button',
                paddingTop: '42px',
                paddingLeft: '15px',
                paddingRight: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '58px',
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            },
            pagination: {
                marginTop: '15px',

                '@media screen and (min-width: 768px)': {
                    marginTop: '30px'
                }
            }
        },

        mediaListing: {
            main: {
                flexDirection: 'row',
                maxWidth: toPx(layoutDimension?.mediaListing?.main),
                padding: '30px 15px 50px',
                margin: 'auto',
                backgroundColor: colorsDefault.background.default,

                '@media screen and (min-width: 768px)': {
                    padding: '40px 25px 80px'
                }
            },

            aside: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: toPx(layoutDimension?.mediaListing?.aside),
                height: '100%',
                paddingTop: 25,
                paddingRight: '15px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingLeft: '30px',
                    paddingRight: '30px'
                }
            },

            listing: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: toPx(layoutDimension?.mediaListing?.listing),
                paddingTop: '15px',

                article: {
                    width: '100%',
                    paddingBottom: '15px',

                    '@media screen and (min-width: 768px)': {
                        paddingBottom: '30px'
                    }
                }
            },

            pagination: {
                marginTop: '15px',

                '@media screen and (min-width: 768px)': {
                    marginTop: '30px'
                }
            }
        },

        archive: {
            ads: {
                billboard: {
                    width: '100%'
                },

                listing: {
                    width: '100%',
                    gridColumn: 'span 12'
                },

                floating: {
                    width: '100%',
                    height: '100%',
                    marginTop: '20px'
                }
            },
            main: {
                flexDirection: 'row',
                maxWidth: toPx(layoutDimension?.archive?.main),
                padding: '30px 15px 50px',
                margin: 'auto',
                backgroundColor: colorsDefault.background.default,

                '@media screen and (min-width: 768px)': {
                    padding: '40px 25px 80px'
                }
            },

            aside: {
                alignSelf: 'stretch',
                flexDirection: 'column',
                display: 'none',
                width: '100%',
                maxWidth: toPx(layoutDimension?.archive?.aside),
                paddingTop: '25px',
                paddingRight: '15px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    display: 'flex',
                    paddingLeft: '30px',
                    paddingRight: '30px'
                }
            },
            listing: {
                maxWidth: '46.875rem',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: 15,

                article: {
                    width: '100%',
                    paddingBottom: '15px',

                    '@media screen and (min-width: 768px)': {
                        paddingBottom: '30px'
                    }
                }
            },
            pagination: {
                marginTop: '15px',

                '@media screen and (min-width: 768px)': {
                    marginTop: '30px'
                }
            }
        },
        // Djevojka dana layout
        djevojkaDana: {
            ads: {
                billboard: {
                    width: '100%'
                }
            },

            main: {
                flexDirection: 'column',
                width: '100%',
                maxWidth: toPx(layoutDimension?.djevojkaDana?.main),
                paddingTop: '24px',
                paddingBottom: '30px',
                margin: 'auto',
                backgroundColor: colorsDefault.background.default,

                '@media screen and (min-width: 768px)': {
                    paddingTop: '30px',
                    paddingBottom: '30px'
                }
            },

            content: {
                container: {
                    flexDirection: 'row'
                },

                main: {
                    width: '100%'
                },

                aside: {
                    flex: `0 0 ${toPx(layoutDimension?.article?.aside)}`,
                    flexDirection: 'column',
                    justifyContent: 'stretch',

                    display: 'none',
                    maxWidth: toPx(layoutDimension?.djevojkaDana?.aside),
                    paddingRight: '0',
                    paddingBottom: '30px',
                    paddingLeft: '0',

                    '@media screen and (min-width: 768px)': {
                        display: 'block',
                        paddingRight: '25px',
                        paddingBottom: '30px',
                        paddingLeft: '25px'
                    }
                }
            },

            prevNext: {
                width: '100%',
                margin: 'auto'
            },

            head: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.djevojkaDana?.head),
                paddingRight: '0',
                paddingLeft: '0',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            },

            tags: {
                width: '100%',
                paddingTop: '25px',
                paddingRight: '15px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '35px',
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            },

            facebook: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.djevojkaDana?.head),
                paddingRight: '15px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            }
        },

        columnists: {
            ads: {
                billboard: {
                    width: '100%'
                },

                listing: {
                    width: '100%',
                    gridColumn: 'span 12'
                }
            },

            main: {
                flexDirection: 'column',
                maxWidth: toPx(layoutDimension?.category?.main),
                padding: '30px 15px 50px',
                margin: 'auto',
                backgroundColor: colorsDefault.background.default,

                '@media screen and (min-width: 768px)': {
                    padding: '40px 25px 80px'
                }
            },

            listing: {
                display: 'grid',
                gridTemplateColumns: '1fr',
                gridRowGap: 10,
                gridColumnGap: 30,
                paddingTop: 15,

                '@media screen and (min-width: 768px)': {
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridRowGap: 30
                }
            },

            pagination: {
                marginTop: '15px',

                '@media screen and (min-width: 768px)': {
                    marginTop: '30px'
                }
            }
        },

        // Embed Product Gallery
        embedProductGallery: {
            main: {
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '100%',
                padding: '0px 10px',
                boxSizing: 'border-box'
            }
        },

        // @TODO: is this even used?
        column: {
            display: 'flex',
            width: '100%',
            maxWidth: toPx(layoutDimension?.column?.main),
            margin: 'auto',
            backgroundColor: colorsDefault.background.default,

            ads: {
                billboard: {
                    margin: 'auto',
                    width: 'auto'
                }
            },
            aside: {
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: toPx(layoutDimension?.column?.aside),
                height: '100%',
                paddingTop: '25px',
                paddingLeft: '15px',
                paddingRight: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '30px',
                    paddingLeft: '30px'
                }
            },

            head: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.column?.head)
            },

            main: {
                flexDirection: 'column',
                width: '100%',
                maxWidth: toPx(layoutDimension?.column?.content),
                paddingTop: '25px',
                paddingLeft: '0',
                paddingRight: '0',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '25px',
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            },

            content: {
                width: '100%',
                paddingLeft: '15px',
                paddingRight: '15px',
                maxWidth: toPx(layoutDimension?.column?.content),

                '@media screen and (min-width: 768px)': {
                    paddingRight: '0',
                    paddingLeft: '0'
                }
            },

            cta: {
                width: '100%',
                padding: '50px 15px',
                maxWidth: toPx(layoutDimension?.column?.content),

                '@media screen and (min-width: 768px)': {
                    padding: '50px 0'
                }
            },

            meta: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.column?.content),
                padding: '20px 15px 30px',

                '@media screen and (min-width: 768px)': {
                    padding: '25px 0 15px'
                }
            },

            mightBeInterested: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.column?.content),
                padding: '20px 15px 30px',

                '@media screen and (min-width: 768px)': {
                    padding: '25px 0 50px'
                }
            },

            linker: {
                width: '100%',
                maxWidth: toPx(layoutDimension?.column?.main),
                paddingRight: '15px',
                paddingBottom: '10px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '0',
                    paddingBottom: '10px',
                    paddingLeft: '0'
                }
            },

            facebook: {
                width: '100%',
                paddingRight: '15px',
                paddingBottom: '30px',
                paddingLeft: '15px',
                maxWidth: toPx(layoutDimension?.column?.content),

                '@media screen and (min-width: 768px)': {
                    paddingRight: '0',
                    paddingBottom: '50px',
                    paddingLeft: '0'
                }
            },
            newsletter: {
                width: '100%',
                paddingRight: '15px',
                paddingBottom: '30px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '0',
                    paddingBottom: '50px',
                    paddingLeft: '0'
                }
            },
            list: {
                width: '100%',
                paddingRight: '15px',
                paddingBottom: '30px',
                paddingLeft: '15px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '0',
                    paddingBottom: '50px',
                    paddingLeft: '0'
                }
            },

            listalica: {
                paddingTop: '40px',
                width: '100%',
                maxWidth: toPx(layoutDimension?.column?.main),
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '50px'
                }
            }
        },
        author: {
            main: {
                flexDirection: 'row',
                alignItemd: 'stretch',
                width: '100%',
                maxWidth: toPx(layoutDimension?.category?.main),
                padding: '30px 15px 50px',
                margin: 'auto',
                backgroundColor: colorsDefault.background.default,

                '@media screen and (min-width: 768px)': {
                    padding: '40px 25px 80px'
                },

                aside: {
                    display: 'none',
                    flexDirection: 'column',
                    width: '100%',
                    maxWidth: toPx(layoutDimension?.article?.aside),
                    paddingTop: '25px',
                    paddingRight: '15px',
                    paddingLeft: '15px',

                    '@media screen and (min-width: 768px)': {
                        display: 'flex',
                        paddingTop: '25px',
                        paddingRight: '25px',
                        paddingLeft: '25px'
                    }
                }
            },

            name: {
                paddingBottom: '15px'
            },

            content: {
                width: '100%',
                maxWidth: toPx(layoutDimension.category.listing)
            },

            listing: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',

                article: {
                    width: '100%',
                    paddingBottom: '15px',

                    '@media screen and (min-width: 768px)': {
                        paddingBottom: '30px'
                    }
                }
            },

            pagination: {
                marginTop: '15px',

                '@media screen and (min-width: 768px)': {
                    marginTop: '30px'
                }
            }
        }
    }
}

export default {
    ...layoutTheme
}
