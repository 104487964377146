// @TODO: Move everything theme related configs from globals folder

import { breakpoints as globalBreakpoints, createMediaQueries } from '../../helpers/theme'
import {
    global,
    normalize,
    shadows as globalShadows,
    transitions as globalTransitions,
    typography as globalTypography,
    utilities,
    zIndex as globalZIndex
} from '../globals'
import { paletteDanas, paletteDefault } from '../globals/colors'
import { cardStyleConfig as cards } from './cards'
import { layoutDimension, layoutTheme } from './layout'

export const baseTheme = {
    colors: {
        ...paletteDefault
    },
    normalize,
    global,
    breakpoints: {
        ...createMediaQueries(globalBreakpoints)
    },
    typography: {
        ...globalTypography
    },
    zIndex: {
        ...globalZIndex
    },
    transitions: {
        ...globalTransitions
    },
    shadows: {
        ...globalShadows
    },
    layoutTheme,
    layoutDimension,
    utilities,
    cards,
    name: 'baseTheme'
}

// Define theme overrides here
export const net = {
    colors: {},
    name: 'net'
}

export const danas = {
    colors: {
        ...paletteDanas
    },
    name: 'danas'
}
