import { createContext, useContext } from 'react'

import { OptimizedEuIzboriData, OptimizedEuKandidatiData } from '../../../helpers/optimizeEuIzboriData'

const defaultValue = {
    results: null,
    polls: null,
    candidates: null
}
type EuIzboriData = {
    results: OptimizedEuIzboriData | null
    polls: OptimizedEuIzboriData | null
    candidates: OptimizedEuKandidatiData | null
}

const EuIzboriContext = createContext<EuIzboriData>(defaultValue)

export const EuIzboriContextProvider = EuIzboriContext.Provider

export const useEuIzboriContext = () => useContext(EuIzboriContext)
