import React, { useContext, useMemo } from 'react'
import { ThemeProvider } from '@emotion/react'
import deepmerge from 'deepmerge'
import PropTypes from 'prop-types'

import { LunaContext } from '@hmn/rtl-web-core/controllers/Luna/Luna'
import { useIsDanasCategory } from '@hmn/rtl-web-core/hooks/useIsDanasCategory'

import { getColors } from '../theme/globals/colors'

export function NetLuna({ theme: themeName, children }) {
    const { themes } = useContext(LunaContext)
    const isDanasCategory = useIsDanasCategory()
    const parsedThemeName = useMemo(
        () => (isDanasCategory ? themeName.replace('net', 'danas') : themeName),
        [isDanasCategory, themeName]
    )

    const mergedTheme = useMemo(() => {
        let result = themes.baseTheme
        if (parsedThemeName && themes?.[parsedThemeName]) {
            result = deepmerge(themes.baseTheme, themes[parsedThemeName])
        }
        return deepmerge(result, { colors: getColors(result.colors) })
    }, [parsedThemeName, themes])

    if (!mergedTheme) {
        return children
    }
    return <ThemeProvider theme={mergedTheme}>{children}</ThemeProvider>
}
NetLuna.propTypes = {
    theme: PropTypes.string.isRequired
}
