import { paletteDanasDark, paletteDark } from '../globals/colors'

export const netDark = {
    colors: {
        ...paletteDark
    },
    name: 'netDark'
}

export const danasDark = {
    colors: {
        ...paletteDark,
        ...paletteDanasDark
    },
    name: 'danasDark'
}
