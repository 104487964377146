import { toRem } from '../../helpers'

export default {
    // baseLayout: {},
    // borderRadius: toRem(15),
    // Icon wrap
    body: {
        overflowX: 'hidden',
        width: '100vw'
    },
    '.svgIcon': {
        display: 'inline-flex',
        verticalAlign: 'middle',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        fontSize: 'inherit',
        lineHeight: 1
    },
    '.adsbox': {
        width: '0px',
        height: '0px',
        overflow: 'hidden',
        marginTop: '-10px',
        zIndex: '-1'
    },
    '.Sidebar_aside': {
        position: 'relative',
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            pointerEvents: 'all'
        }
    },
    header: {
        height: {
            md: {
                open: 50,
                closed: 50
            },
            xs: {
                open: 50,
                closed: 40
            },
            open: 50,
            closed: 40
        }
    },
    navigation: {
        panel: {
            // values typeof number are treated as px and converted to rem, string values are passed on
            maxWidth: {
                lg: 1280,
                md: '100vw',
                sm: `calc( 100vw - ${toRem(50)})`,
                xs: '100vw'
            }
        }
    },
    htmlContent: {
        title: {
            marginBottom: 0
        },
        article: {
            marginLeft: 0,
            marginRight: 0,
            xs: {
                marginBottom: 11
            },
            md: {
                marginBottom: 21
            }
        },
        image: {
            xs: {
                marginBottom: 20
            },
            md: {
                marginBottom: 25
            }
        },
        xs: {
            blockVerticalMargin: 11
        },
        md: {
            blockVerticalMargin: 21
        }
    }
}
