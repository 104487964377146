export const defaults = {
    fontFamily: {
        default: 'proxima-nova, ArialToProximaNova',
        alternative: 'proxima-nova-condensed, ArialToProximaNovaCond',
        extra: 'proxima-nova-extra-condensed'
    },
    fontDisplay: 'swap',
    fontSize: 18,
    fontSizes: {
        // [type]: [xs, sm, md, lg, xl, xxl]
        body: [20, 20, 20, 20, 20, 20],
        p: [20, 20, 20, 20, 20, 20],
        ul: [20, 20, 20, 20, 20, 20],
        ol: [20, 20, 20, 20, 20, 20],
        dl: [20, 20, 22, 22, 22, 22],
        table: [20, 20, 22, 22, 22, 22],
        h1: [24, 24, 40, 40, 40, 40],
        h2: [24, 24, 36, 36, 36, 36],
        h3: [20, 20, 32, 32, 32, 32],
        h4: [20, 20, 24, 24, 24, 24],
        h5: [20, 20, 22, 22, 22, 22],
        h6: [20, 20, 20, 20, 20, 20],
        small: [15, 15, 22, 22, 22, 22],
        blockquote: [20, 20, 20, 20, 20, 20],
        '.title_subtitle': [24, 24, 40, 40, 40, 40]
    },
    fontStyle: {
        normal: 'normal',
        italic: 'italic'
    },
    fontVariant: {
        normal: 'normal'
    },
    lineHeight: 1.6,
    fontWeight: {
        extraLight: 200,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
        extraBold: 800,
        black: 900
    }
}

const lineHeights = {
    body: 1.7,
    heading: {
        h1: 1.2,
        h2: 1.2,
        h3: 1.2,
        h4: 1.2,
        h5: 1.2,
        h6: 1.2,
        '.title_subtitle': 1.2
    },
    paragraph: 1.7,
    button: 2,
    // input: 1.6,
    blockquote: 1.6,
    list: 1.7
}

const rendering = {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'greyscale',
    textRendering: 'optimizeLegibility'
}

const fontSettings = {
    html: {
        ...rendering,
        fontFamily: `${defaults.fontFamily.default}, sans-serif`
    },
    body: {
        lineHeight: lineHeights.body,
        letterSpacing: 0
    },
    h1: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.extraBold,
        lineHeight: lineHeights.heading.h1,
        letterSpacing: -1
    },
    h2: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.extraBold,
        lineHeight: lineHeights.heading.h2
    },
    h3: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.bold,
        lineHeight: lineHeights.heading.h3
    },
    h4: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.bold,
        lineHeight: lineHeights.heading.h4
    },
    h5: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.bold,
        lineHeight: lineHeights.heading.h5
    },
    h6: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.bold,
        lineHeight: lineHeights.heading.h6
    },
    p: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.regular,
        lineHeight: lineHeights.paragraph
    },
    ul: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.regular,
        lineHeight: lineHeights.list
    },
    ol: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.regular,
        lineHeight: lineHeights.list
    },
    small: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.regular
    },
    button: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.black,
        lineHeight: lineHeights.button
    },
    // input: {
    //     fontFamily: `${defaults.fontFamily.default}, sans-serif`,
    //     fontWeight: defaults.fontWeight.regular,
    //     lineHeight: lineHeights.input
    // },
    // textarea: {
    //     fontFamily: `${defaults.fontFamily.default}, sans-serif`,
    //     fontWeight: defaults.fontWeight.regular,
    //     lineHeight: lineHeights.input
    // },
    // select: {
    //     fontFamily: `${defaults.fontFamily.default}, sans-serif`,
    //     fontWeight: defaults.fontWeight.regular,
    //     lineHeight: lineHeights.input
    // },
    blockquote: {
        fontFamily: `${defaults.fontFamily.alternative}, sans-serif`,
        fontWeight: defaults.fontWeight.regular,
        lineHeight: lineHeights.blockquote,
        fontStyle: defaults.fontStyle.italic
    },

    '.title_subtitle': {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.extraBold,
        lineHeight: lineHeights.heading.h1,
        letterSpacing: -1
    }
}

export default {
    fontSettings,
    fontFamily: `${defaults.fontFamily.default}, sans-serif`,
    fontFamilyAlt: `${defaults.fontFamily.alternative}, sans-serif`,
    fontFamilyExtra: `${defaults.fontFamily.extra}, sans-serif`,
    font: {
        default: defaults.fontFamily.default,
        display: defaults.fontDisplay,
        weight: defaults.fontWeight,
        style: defaults.fontStyle,
        variant: defaults.fontVariant
    },
    fontSizes: defaults.fontSizes,
    fontWeight: defaults.fontWeight,
    rootFontSize: defaults.fontSize
}
