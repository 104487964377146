const imageDimensions = (type, isMobile) => {
    if (isMobile) {
        if (type === 'listing') {
            return [
                {
                    width: 340,
                    height: 206
                },
                {
                    width: 110,
                    height: 110
                }
            ]
        }
    } else if (type === 'listing') {
        return [
            {
                width: 740,
                height: 442
            },
            {
                width: 255,
                height: 170
            }
        ]
    }
    return []
}

export default imageDimensions
