import { toRems } from '../../../helpers/theme'

const grid = {
    // 12 columns grid
    12: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridColumnGap: toRems([20, 20, 20, 25, 25, 40], true),
        gridRowGap: toRems([15, 15, 15, 25, 25, 40], true),
        width: '100%',
        maxWidth: '100%',
        px: toRems([15, 15, 15, 20, 40, 40], true),
        col: {
            // 1 row
            '1x1': {
                gridColumn: 'span 1',
                gridRow: 'span 1'
            },
            '2x1': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 2', 'span 2', 'span 2'],
                gridRow: 'span 1'
            },
            '3x1': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 3', 'span 3', 'span 3'],
                gridRow: 'span 1'
            },
            '4x1': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 4', 'span 4', 'span 4'],
                gridRow: 'span 1'
            },
            '5x1': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 5', 'span 5', 'span 5'],
                gridRow: 'span 1'
            },
            '6x1': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 6', 'span 6', 'span 6'],
                gridRow: 'span 1'
            },
            '7x1': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7'],
                gridRow: 'span 1'
            },
            '8x1': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 8', 'span 8', 'span 8'],
                gridRow: 'span 1'
            },
            '9x1': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 9', 'span 9', 'span 9'],
                gridRow: 'span 1'
            },
            '10x1': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 10', 'span 10', 'span 10'],
                gridRow: 'span 1'
            },
            '11x1': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 11', 'span 11', 'span 11'],
                gridRow: 'span 1'
            },
            '12x1': {
                gridColumn: 'span 12',
                gridRow: 'span 1'
            },
            // 2 rows
            '1x2': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 1', 'span 1', 'span 1'],
                gridRow: 'span 2'
            },
            '2x2': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 2', 'span 2', 'span 2'],
                gridRow: 'span 2'
            },
            '3x2': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 3', 'span 3', 'span 3'],
                gridRow: 'span 2'
            },
            '4x2': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 4', 'span 4', 'span 4'],
                gridRow: 'span 2'
            },
            '5x2': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 5', 'span 5', 'span 5'],
                gridRow: 'span 2'
            },
            '6x2': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 6', 'span 6', 'span 6'],
                gridRow: 'span 2'
            },
            '7x2': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7'],
                gridRow: 'span 2'
            },
            '8x2': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 8', 'span 8', 'span 8'],
                gridRow: 'span 2'
            },
            '9x2': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 9', 'span 9', 'span 9'],
                gridRow: 'span 2'
            },
            '10x2': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 10', 'span 10', 'span 10'],
                gridRow: 'span 2'
            },
            '11x2': {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 11', 'span 11', 'span 11'],
                gridRow: 'span 2'
            },
            '12x2': {
                gridColumn: 'span 12',
                gridRow: 'span 2'
            }
        }
    }
}

export default {
    grid
}
