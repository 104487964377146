import isNumeric from '../utils/isNumeric'

/**
 * Convert numbers to px
 * @param size
 * @param fontSize
 * @returns {string}
 */
const convertToPx = size => (isNumeric(size) ? `${size}px` : size)

export default convertToPx
