// We need to centralize the zIndex definitions as they work like global values in the browser.
export default {
    under: '-1',
    over: 1,
    footer: 10,
    ad: 11,
    wallpaper: 20,
    navigationDesktop: 42,
    header: 43,
    tooltip: 50,
    modal: 600,
    navigationMobile: 650,
    cookieNotice: 700,
    emperor: 1001, // rule them all
    floatingAd: 100000,
    floatingVideo: 900000 // needs to be so high because it clashes with out of page creatives (randomly high zIndexes)
}
