/* eslint-disable quote-props, no-dupe-keys, max-len */
import merge from 'deepmerge'

import { createFontVariations } from '../../helpers/theme'

const style = theme => {
    const { fontSizes, rootFontSize, fontSettings } = theme.typography
    const globals = {
        html: {
            fontSize: '100%',
            [theme.breakpoints.up('md')]: {
                // fluid resize magic, with the magic number of 0.834vw
                // fontSize: 'clamp(80%, 0.834vw, 100%)'
            }
        },
        body: {
            color: theme.colors.text.default
        },
        p: {
            color: theme.colors.text.default
        },
        h1: {
            color: theme.colors.text.default
        },
        h2: {
            color: theme.colors.text.default
        },
        h3: {
            color: theme.colors.text.default
        },
        h4: {
            color: theme.colors.text.default
        },
        a: {
            color: theme.colors.link.alpha.text.default,
            textDecoration: 'none'
        },
        'ol, ul': {
            margin: 0,
            padding: 0,
            listStyleType: 'none'
        },
        '& #amp-lightbox-gallery + div': {
            display: 'none'
        },
        '& .amp-sidebar-mask': {
            display: 'none'
        },
        '& #mobile': {
            [theme.breakpoints.up('lg')]: {
                display: 'none'
            }
        },
        '& #desktop': {
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        },
        '& #mobileScaleDown, & .hideDeskSm': {
            [theme.breakpoints.up('md')]: {
                display: 'none !important'
            }
        },
        '& #desktopScaleDown, & .desktopScaleDown, & .hideMobSm': {
            [theme.breakpoints.down('sm')]: {
                display: 'none !important'
            }
        }
    }
    return merge.all([
        theme.normalize,
        theme.utilities,
        fontSettings,
        createFontVariations({ fontSizes, rootFontSize }),
        theme.global,
        globals
    ])
}

export default style
