const findParagraphContent = page =>
    page?.content
        ?.filter(block => block.name === 'core/paragraph' && block.attributes?.content)
        ?.map(b => b.attributes.content) || []

const findFirstTitle = page => {
    if (!page?.content) {
        return undefined
    }
    const heading = page.content.find(block => block.name === 'core/heading' && block.attributes?.content)
    if (!heading) {
        return undefined
    }
    return heading.attributes.content
}

const findImageIds = page =>
    page?.content
        ?.filter(block => block.name === 'hmn/image' && block.attributes?.id)
        ?.map(block => block.attributes?.id) || []

export { findParagraphContent, findFirstTitle, findImageIds }
