const layoutDimension = {
    homepage: {
        content: 1300
    },
    article: {
        head: 750,
        main: 1170,
        content: 750,
        related: 750,
        aside: 420
    },
    tag: {
        main: 1170,
        listing: 750
    },
    recipe: {
        content: 1150,
        related: 1215
    },
    search: {
        main: 1170,
        listing: 750
    },
    counseling: {
        head: 1200,
        main: 1200,
        content: 900,
        meta: 900,
        related: 1200
    },
    static: {
        head: 1300,
        content: 950
    },
    error: {
        head: 920,
        main: 1150
    },
    media: {
        main: 1170,
        content: 750,
        meta: 750,
        aside: 420,
        related: 1170
    },
    mediaListing: {
        main: 1170,
        listing: 750,
        aside: 420,
        button: 255
    },
    category: {
        main: 1190,
        listing: 750,
        aside: 420,
        button: 255
    },
    archive: {
        main: 1170,
        listing: 750,
        aside: 420
    },
    column: {
        head: 750,
        main: 1170,
        content: 750,
        related: 750,
        aside: 420
    },
    product: {
        content: 1200,
        listing: 1400
    },
    djevojkaDana: {
        main: 1170,
        head: 750,
        aside: 420
    },
    giveaway: {
        main: 900
    },
    tester: {
        head: 1000
    },
    calculator: {
        main: 1215
    }
}

export default {
    ...layoutDimension
}
