// https://github.com/garmeeh/next-seo#default-seo-configuration
export default {
    titleTemplate: `%s - ${process.env.NEXT_PUBLIC_APP_NAME_NET}`,
    openGraph: {
        type: 'website',
        locale: 'hr_HR',
        url: process.env.NEXT_PUBLIC_APP_ROOT_NET,
        site_name: process.env.NEXT_PUBLIC_APP_NAME_NET
    },
    facebook: {
        appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID
    },
    twitter: {
        handle: '@nethr',
        site: '@nethr',
        cardType: 'summary_large_image'
    }
}
