import { createContext, PropsWithChildren, useContext } from 'react'

const CountryCodeContext = createContext<null | string>(null)

export const CountryCodeProvider = ({ value, children }: PropsWithChildren<{ value: string }>) => (
    <CountryCodeContext.Provider value={value}>{children}</CountryCodeContext.Provider>
)
export const useCountryCodeContext = () => useContext(CountryCodeContext)

// For Croatia, treat null as inside Croatia
export const useIsCroatiaCountryCode = () => {
    const countryCode = useCountryCodeContext()
    return countryCode === 'HR' || countryCode === null
}

export const useIsOutsideCroatia = () => {
    const countryCode = useCountryCodeContext()
    return countryCode !== 'HR' && countryCode !== null
}
